import revive_payload_client_4sVQNw7RlN from "/Users/i/dev/vauto/ivv/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/i/dev/vauto/ivv/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/i/dev/vauto/ivv/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/i/dev/vauto/ivv/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/i/dev/vauto/ivv/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/Users/i/dev/vauto/ivv/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/i/dev/vauto/ivv/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/i/dev/vauto/ivv/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/i/dev/vauto/ivv/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import pinna_LB7S2HV5Ah from "/Users/i/dev/vauto/ivv/plugins/pinna.ts";
import wang_editor_k25nlSAv4q from "/Users/i/dev/vauto/ivv/plugins/wang-editor.ts";
import tdesign_Xp0CCqfM5K from "/Users/i/dev/vauto/ivv/plugins/tdesign.ts";
import vuedraggable_p3ImtCUeuI from "/Users/i/dev/vauto/ivv/plugins/vuedraggable.ts";
import vuetify_7h9QAQEssH from "/Users/i/dev/vauto/ivv/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  pinna_LB7S2HV5Ah,
  wang_editor_k25nlSAv4q,
  tdesign_Xp0CCqfM5K,
  vuedraggable_p3ImtCUeuI,
  vuetify_7h9QAQEssH
]