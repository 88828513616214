import { default as _91home_id_93ouIwLtgd3hMeta } from "/Users/i/dev/vauto/ivv/pages/home/news/[home_id].vue?macro=true";
import { default as _91home_id_937XxQwzYatHMeta } from "/Users/i/dev/vauto/ivv/pages/home/swiper/[home_id].vue?macro=true";
import { default as indexzalLbGICQNMeta } from "/Users/i/dev/vauto/ivv/pages/index.vue?macro=true";
import { default as loginI7x7MHxrUbMeta } from "/Users/i/dev/vauto/ivv/pages/login.vue?macro=true";
import { default as _91id_93WsCobX697JMeta } from "/Users/i/dev/vauto/ivv/pages/news_coupon/edit/[id].vue?macro=true";
import { default as indexgcxbme9Z3eMeta } from "/Users/i/dev/vauto/ivv/pages/news_coupon/index.vue?macro=true";
import { default as _91id_93uBGPCA6FWIMeta } from "/Users/i/dev/vauto/ivv/pages/news/edit/[id].vue?macro=true";
import { default as index8eeAuNcZqLMeta } from "/Users/i/dev/vauto/ivv/pages/news/index.vue?macro=true";
import { default as _91id_93ZfcoQJNs9sMeta } from "/Users/i/dev/vauto/ivv/pages/news/view/[id].vue?macro=true";
import { default as _91id_93jHhPvivOAtMeta } from "/Users/i/dev/vauto/ivv/pages/route/form/[id].vue?macro=true";
import { default as index15NO3ve4jtMeta } from "/Users/i/dev/vauto/ivv/pages/route/index.vue?macro=true";
import { default as _91appid_93E5kaiir8yMMeta } from "/Users/i/dev/vauto/ivv/pages/wxamp_user/[appid].vue?macro=true";
import { default as _91appid_93pQ1Ybxx1qbMeta } from "/Users/i/dev/vauto/ivv/pages/wxamp/setting/[appid].vue?macro=true";
export default [
  {
    name: _91home_id_93ouIwLtgd3hMeta?.name ?? "home-news-home_id",
    path: _91home_id_93ouIwLtgd3hMeta?.path ?? "/home/news/:home_id()",
    meta: { ...(_91home_id_93ouIwLtgd3hMeta || {}), ...{"middleware":["auth"]} },
    alias: _91home_id_93ouIwLtgd3hMeta?.alias || [],
    redirect: _91home_id_93ouIwLtgd3hMeta?.redirect,
    component: () => import("/Users/i/dev/vauto/ivv/pages/home/news/[home_id].vue").then(m => m.default || m)
  },
  {
    name: _91home_id_937XxQwzYatHMeta?.name ?? "home-swiper-home_id",
    path: _91home_id_937XxQwzYatHMeta?.path ?? "/home/swiper/:home_id()",
    meta: { ...(_91home_id_937XxQwzYatHMeta || {}), ...{"middleware":["auth"]} },
    alias: _91home_id_937XxQwzYatHMeta?.alias || [],
    redirect: _91home_id_937XxQwzYatHMeta?.redirect,
    component: () => import("/Users/i/dev/vauto/ivv/pages/home/swiper/[home_id].vue").then(m => m.default || m)
  },
  {
    name: indexzalLbGICQNMeta?.name ?? "index",
    path: indexzalLbGICQNMeta?.path ?? "/",
    meta: { ...(indexzalLbGICQNMeta || {}), ...{"middleware":["auth"]} },
    alias: indexzalLbGICQNMeta?.alias || [],
    redirect: indexzalLbGICQNMeta?.redirect,
    component: () => import("/Users/i/dev/vauto/ivv/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginI7x7MHxrUbMeta?.name ?? "login",
    path: loginI7x7MHxrUbMeta?.path ?? "/login",
    meta: { ...(loginI7x7MHxrUbMeta || {}), ...{"middleware":["auth"]} },
    alias: loginI7x7MHxrUbMeta?.alias || [],
    redirect: loginI7x7MHxrUbMeta?.redirect,
    component: () => import("/Users/i/dev/vauto/ivv/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91id_93WsCobX697JMeta?.name ?? "news_coupon-edit-id",
    path: _91id_93WsCobX697JMeta?.path ?? "/news_coupon/edit/:id()",
    meta: { ...(_91id_93WsCobX697JMeta || {}), ...{"middleware":["auth"]} },
    alias: _91id_93WsCobX697JMeta?.alias || [],
    redirect: _91id_93WsCobX697JMeta?.redirect,
    component: () => import("/Users/i/dev/vauto/ivv/pages/news_coupon/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexgcxbme9Z3eMeta?.name ?? "news_coupon",
    path: indexgcxbme9Z3eMeta?.path ?? "/news_coupon",
    meta: { ...(indexgcxbme9Z3eMeta || {}), ...{"middleware":["auth"]} },
    alias: indexgcxbme9Z3eMeta?.alias || [],
    redirect: indexgcxbme9Z3eMeta?.redirect,
    component: () => import("/Users/i/dev/vauto/ivv/pages/news_coupon/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93uBGPCA6FWIMeta?.name ?? "news-edit-id",
    path: _91id_93uBGPCA6FWIMeta?.path ?? "/news/edit/:id()",
    meta: { ...(_91id_93uBGPCA6FWIMeta || {}), ...{"middleware":["auth"]} },
    alias: _91id_93uBGPCA6FWIMeta?.alias || [],
    redirect: _91id_93uBGPCA6FWIMeta?.redirect,
    component: () => import("/Users/i/dev/vauto/ivv/pages/news/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: index8eeAuNcZqLMeta?.name ?? "news",
    path: index8eeAuNcZqLMeta?.path ?? "/news",
    meta: { ...(index8eeAuNcZqLMeta || {}), ...{"middleware":["auth"]} },
    alias: index8eeAuNcZqLMeta?.alias || [],
    redirect: index8eeAuNcZqLMeta?.redirect,
    component: () => import("/Users/i/dev/vauto/ivv/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ZfcoQJNs9sMeta?.name ?? "news-view-id",
    path: _91id_93ZfcoQJNs9sMeta?.path ?? "/news/view/:id()",
    meta: { ...(_91id_93ZfcoQJNs9sMeta || {}), ...{"middleware":["auth"]} },
    alias: _91id_93ZfcoQJNs9sMeta?.alias || [],
    redirect: _91id_93ZfcoQJNs9sMeta?.redirect,
    component: () => import("/Users/i/dev/vauto/ivv/pages/news/view/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93jHhPvivOAtMeta?.name ?? "route-form-id",
    path: _91id_93jHhPvivOAtMeta?.path ?? "/route/form/:id()",
    meta: { ...(_91id_93jHhPvivOAtMeta || {}), ...{"middleware":["auth"]} },
    alias: _91id_93jHhPvivOAtMeta?.alias || [],
    redirect: _91id_93jHhPvivOAtMeta?.redirect,
    component: () => import("/Users/i/dev/vauto/ivv/pages/route/form/[id].vue").then(m => m.default || m)
  },
  {
    name: index15NO3ve4jtMeta?.name ?? "route",
    path: index15NO3ve4jtMeta?.path ?? "/route",
    meta: { ...(index15NO3ve4jtMeta || {}), ...{"middleware":["auth"]} },
    alias: index15NO3ve4jtMeta?.alias || [],
    redirect: index15NO3ve4jtMeta?.redirect,
    component: () => import("/Users/i/dev/vauto/ivv/pages/route/index.vue").then(m => m.default || m)
  },
  {
    name: _91appid_93E5kaiir8yMMeta?.name ?? "wxamp_user-appid",
    path: _91appid_93E5kaiir8yMMeta?.path ?? "/wxamp_user/:appid()",
    meta: { ...(_91appid_93E5kaiir8yMMeta || {}), ...{"middleware":["auth"]} },
    alias: _91appid_93E5kaiir8yMMeta?.alias || [],
    redirect: _91appid_93E5kaiir8yMMeta?.redirect,
    component: () => import("/Users/i/dev/vauto/ivv/pages/wxamp_user/[appid].vue").then(m => m.default || m)
  },
  {
    name: _91appid_93pQ1Ybxx1qbMeta?.name ?? "wxamp-setting-appid",
    path: _91appid_93pQ1Ybxx1qbMeta?.path ?? "/wxamp/setting/:appid()",
    meta: { ...(_91appid_93pQ1Ybxx1qbMeta || {}), ...{"middleware":["auth"]} },
    alias: _91appid_93pQ1Ybxx1qbMeta?.alias || [],
    redirect: _91appid_93pQ1Ybxx1qbMeta?.redirect,
    component: () => import("/Users/i/dev/vauto/ivv/pages/wxamp/setting/[appid].vue").then(m => m.default || m)
  }
]